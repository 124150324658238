<template>
  <el-card class="tcu-list height100percent">
    <div id="searchBar">
      <SearchBar
        :searchBtns="searchBtns"
        :searchFormItems="searchFormItems"
        @search="searchHandler"
      />
    </div>
    <div class="tcu-list_table">
      <CommonTable
        ref="tcuTable"
        :tableItems="tableItems"
        :actions="actions"
        :getTableDataFunc="getTcuList"
        table-type="tcus"
        :outParams="params"
      />
    </div>
  </el-card>
</template>

<script>
import SearchBar from '@/components/SearchBar'
import CommonTable from '@/components/CommonTable/index.vue'

import { permitActionMixin } from '@/mixin/permitActionMixin'
import {
  getTcuList
} from '@/services'
import jsonData from './jsonData'
export default {
  components: {
    SearchBar,
    CommonTable
  },
  mixins: [permitActionMixin],
  data () {
    return {
      searchBtns: jsonData.searchBtns,
      searchFormItems: jsonData.searchFormItems,
      tableItems: jsonData.tableItems,
      getTcuList,
      params: {}
    }
  },
  created () {
    this.initActionData(jsonData, 'tcus')
  },
  methods: {
    searchHandler (form) {
      if (form.actual_admission_date_gteq && form.actual_admission_date_gteq.length > 0) {
        const arr = form.actual_admission_date_gteq
        delete form.actual_admission_date_gteq
        form.actual_admission_date_gteq = arr[0]
        form.actual_admission_date_lteq = arr[1]
      }
      if (form.actual_discharge_date_gteq && form.actual_discharge_date_gteq.length > 0) {
        const arr = form.actual_discharge_date_gteq
        delete form.actual_discharge_date_gteq
        form.actual_discharge_date_gteq = arr[0]
        form.actual_discharge_date_lteq = arr[1]
      }
      this.$set(this, 'params', form)
      this.$nextTick(() => {
        if (this.$refs.tcuTable) {
          this.$refs.tcuTable.reloadData()
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.tcu-list {
  &_table {
    height: calc(100% - 72px);
  }
}
::v-deep {
  .el-card__body {
    height: 100%;
    box-sizing: border-box;
  }
}
</style>