export default {
  searchFormItems: [
    {
      formtype: 'daterange',
      prop: 'planned_at_gteq',
      label: '',
      placeholder: 'TCU Date',
      object: {
        style: 'width: 238px; margin: 0 15px 15px 0;',
        startPlaceholder: 'TCU Date',
        endPlaceholder: 'TCU Date'
      }
    },
    {
      formtype: 'input',
      prop: 'address_cont',
      label: '',
      placeholder: 'Location',
      object: {
        style: 'width: 168px; margin: 0 15px 15px 0;'
      }
    },
    {
      formtype: 'input',
      prop: 'patient_ic_cont',
      label: '',
      placeholder: 'Patient IC',
      object: {
        style: 'width: 168px; margin: 0 15px 15px 0;'
      }
    },
    {
      formtype: 'select',
      prop: 'transport_arranged_eq',
      label: '',
      placeholder: 'Transport Arranged',
      options: [
        {
          name: 'Yes',
          value: true
        },
        {
          name: 'No',
          value: false
        }
      ],
      object: {
        style: 'width: 202px;margin: 0 0 15px 0;'
      }
    }
  ],
  searchBtns: [
    {
      type: 'primary',
      label: 'Filter',
      style: 'width: 98px;',
      methodsType: 'submit'
    },
    {
      type: '',
      label: 'Clear',
      style: 'width: 98px;margin-left: 12px;',
      methodsType: 'clear'
    }
  ],
  tableItems: [
    {
      prop: 'patient',
      label: 'Record Number',
      classname: 'text-blue alink',
      width: '180px',
      objectKey: 'number',
      clickInfo: {
        url: '/workplace/patientInfo/details',
        key: 'patient_id'
      }
    },
    {
      prop: 'patient',
      label: 'Patient',
      classname: '',
      list: [
        {
          label: '',
          prop: 'patient',
          objectKey: 'name'
        },
        {
          label: '',
          prop: 'patient',
          objectKey: 'ic'
        }
      ]
    },
    {
      prop: 'planned_at',
      label: 'Date/Time',
      classname: ''
    },
    {
      prop: 'address',
      label: 'Location',
      width: '105px',
      classname: ''
    },
    {
      prop: 'transport_arranged',
      label: 'Transport Arranged',
      classname: ''
    },
    {
      prop: 'has_accompany',
      label: 'HCA Accompany',
      classname: ''
    },
    {
      prop: 'transport_remarks',
      label: 'Admin Remarks',
      classname: ''
    },
    {
      prop: 'remarks',
      label: 'Doctor Remarks',
      classname: ''
    }
  ],
  tableActions: [
    {
      label: 'Edit',
      url: '/workplace/patientInfo/update?formType=tcu',
      type: 'jump',
      id: 'update',
      params: [
        {
          key: 'tid',
          prop: 'id'
        },
        {
          key: 'id',
          prop: 'patient_id'
        }
      ]
    }
  ]
}